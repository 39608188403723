import * as yup from 'yup';
import { IEnquiryDto, IEnquiryOptionDto } from '../enquiry-dto';
import { DateOnly } from '../../utility';
import { TFunction } from 'i18next';

export function enquiryOptionDtoValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<IEnquiryOptionDto> {
  return yup
    .object({
      name: yup.string().defined(),
      valueEnabled: yup.bool().defined(),
    })
    .required();
}

export function enquiryDtoStrictValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<IEnquiryDto> {
  return yup
    .object({
      from: yup
        .date()
        .transform((e) => (e ? new DateOnly(e) : null))
        .typeError(translation('errors.required'))
        .required(translation('errors.required')),

      to: yup
        .date()
        .transform((e) => (e ? new DateOnly(e) : null))
        .typeError(translation('errors.required'))
        .required(translation('errors.required')),

      flexible: yup
        .number()
        .transform((e) => (isNaN(e) ? null : e))
        .nullable()
        .defined(),

      personCount: yup
        .number()
        .transform((e) => (isNaN(e) ? null : e))
        .required(translation('errors.required')),

      airportTransfer: yup.bool().nullable().defined(),
      newsletter: yup.bool().nullable().defined(),

      contactMail: yup
        .string()
        .trim()
        .defined()
        .email(translation('errors.required'))
        .required(translation('errors.required')),

      contactPhone: yup.string().trim().nullable().defined(),
      contactTitle: yup.string().trim().nullable().defined(),

      contactFirstname: yup
        .string()
        .trim()
        .required(translation('errors.required')),

      contactLastname: yup
        .string()
        .trim()
        .required(translation('errors.required')),

      contactLanguage: yup
        .string()
        .trim()
        .required(translation('errors.required')),
      contactStreet: yup.string().trim().nullable().defined(),
      contactStreetNr: yup.string().trim().nullable().defined(),
      contactPostal: yup.string().trim().nullable().defined(),
      contactCity: yup.string().trim().nullable().defined(),
      contactCountry: yup.string().trim().nullable().defined(),

      promoCode: yup.string().trim().defined().nullable(),
      callback: yup
        .date()
        .transform((e) => {
          if (!e || isNaN(e) || e === '') {
            return null;
          }
          return e;
        })
        .defined()
        .nullable()
        .default(null),

      roomOptions: yup
        .array()
        .of<IEnquiryOptionDto>(enquiryOptionDtoValidation(translation))
        .required(),

      treatmentOptions: yup
        .array()
        .of<IEnquiryOptionDto>(enquiryOptionDtoValidation(translation))
        .required(),

      message: yup.string().trim().nullable().defined(),
    })
    .defined();
}

export function enquiryDtoValidation(
  translation: TFunction<'translation', undefined>
): yup.ObjectSchema<IEnquiryDto> {
  return yup
    .object({
      from: yup
        .date()
        .transform((e) => (e ? new DateOnly(e) : null))
        .typeError(translation('errors.required'))
        .nullable()
        .defined(),

      to: yup
        .date()
        .transform((e) => (e ? new DateOnly(e) : null))
        .typeError(translation('errors.required'))
        .nullable()
        .defined(),

      flexible: yup
        .number()
        .transform((e) => (isNaN(e) ? null : e))
        .nullable()
        .defined(),

      personCount: yup
        .number()
        .transform((e) => (isNaN(e) ? null : e))
        .nullable()
        .defined(),

      airportTransfer: yup.bool().nullable().defined(),
      newsletter: yup.bool().nullable().defined(),

      contactMail: yup
        .string()
        .trim()
        .defined()
        .email(translation('errors.required'))
        .required(translation('errors.required')),

      contactPhone: yup.string().trim().nullable().defined(),
      contactTitle: yup.string().trim().nullable().defined(),

      contactFirstname: yup.string().trim().nullable().defined(),
      contactLastname: yup.string().trim().nullable().defined(),
      contactLanguage: yup
        .string()
        .trim()
        .required(translation('errors.required')),
      contactStreet: yup.string().trim().nullable().defined(),
      contactStreetNr: yup.string().trim().nullable().defined(),
      contactPostal: yup.string().trim().nullable().defined(),
      contactCity: yup.string().trim().nullable().defined(),
      contactCountry: yup.string().trim().nullable().defined(),

      promoCode: yup.string().trim().defined().nullable(),
      callback: yup
        .date()
        .transform((e) => {
          if (!e || isNaN(e) || e === '') {
            return null;
          }
          return e;
        })
        .defined()
        .nullable()
        .default(null),

      roomOptions: yup
        .array()
        .of<IEnquiryOptionDto>(enquiryOptionDtoValidation(translation))
        .required(),

      treatmentOptions: yup
        .array()
        .of<IEnquiryOptionDto>(enquiryOptionDtoValidation(translation))
        .required(),

      message: yup.string().trim().nullable().defined(),
    })
    .defined();
}
