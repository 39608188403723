import { ErrorInfo, StrictMode, Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { EnquiryPage } from './app/enquiry';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import LangRedirect from './app/lang-redirect';
import Finish from './app/enquiry/finish';
import Reservation from './app/reservation/reservation';
import Voucher from './app/voucher/voucher';
import { ThankYou as ThankYouReservation } from './app/reservation/thank-you';
import { PaymentError as PaymentErrorReservation } from './app/reservation/payment-error';
import { ThankYou as ThankYouVoucher } from './app/voucher/thank-you';
import { PaymentError as PaymentErrorVoucher } from './app/voucher/payment-error';
import { SiteChangeTracker } from './site-change-tracker';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './error';
import * as Sentry from '@sentry/react';
import { captureConsoleIntegration } from '@sentry/integrations';
import Main from './app/offer-package/main';
import { ThankYou as ThankYouOfferPackage } from './app/offer-package/thank-you';
import { PaymentError as PaymentErrorOfferPackage } from './app/offer-package/payment-error';
import Booking from './app/offer-package/booking';
import { ConsultationPage } from './app/consultation';

// Sentry.init({
//   dsn: 'https://41bbfc6f52873c03e65e252786b312de@o4506956614205440.ingest.us.sentry.io/4506956616630272',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//     captureConsoleIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi) // passes i18n down to react-i18next
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    ns: ['common', 'offer-package'],
    defaultNS: 'common',

    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    fallbackLng: 'de',
    supportedLngs: ['en', 'de'],

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    detection: {
      order: ['path', 'localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'language',
      lookupFromPathIndex: 0,
      // convertDetectedLanguage: 'Iso15897',
      convertDetectedLanguage: (lng) => lng.replace('-', '_'),
    },

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <LangRedirect></LangRedirect>,
    errorElement: <ErrorFallback></ErrorFallback>,
  },
  {
    path: 'de',
    element: <SiteChangeTracker></SiteChangeTracker>,
    children: [
      {
        path: 'finish',
        element: <Finish />,
      },
      {
        path: '',
        element: <EnquiryPage />,
      },
      {
        path: 'consultation',
        element: <ConsultationPage />,
      },
      {
        path: 'reservation',
        children: [
          {
            path: 'finish',
            element: <ThankYouReservation></ThankYouReservation>,
          },
          {
            path: 'payment-cancelled',
            element: <PaymentErrorReservation></PaymentErrorReservation>,
          },
          {
            path: '',
            element: <Reservation />,
          },
        ],
      },
      {
        path: 'offer-package',
        children: [
          {
            path: 'finish',
            element: <ThankYouOfferPackage></ThankYouOfferPackage>,
          },
          {
            path: 'payment-cancelled',
            element: <PaymentErrorOfferPackage></PaymentErrorOfferPackage>,
          },
          {
            path: '',
            element: <Main />,
          },
        ],
      },
      {
        path: 'offer-reservation',
        children: [
          {
            path: 'finish',
            element: <ThankYouOfferPackage></ThankYouOfferPackage>,
          },
          {
            path: 'payment-cancelled',
            element: <PaymentErrorOfferPackage></PaymentErrorOfferPackage>,
          },
          {
            path: '',
            element: <Booking />,
          },
        ],
      },
      {
        path: 'voucher',
        children: [
          {
            path: 'finish',
            element: <ThankYouVoucher></ThankYouVoucher>,
          },
          {
            path: 'payment-cancelled',
            element: <PaymentErrorVoucher></PaymentErrorVoucher>,
          },
          {
            path: '',
            element: <Voucher />,
          },
        ],
      },
    ],
    errorElement: <ErrorFallback></ErrorFallback>,
  },
  {
    path: 'en',
    element: <SiteChangeTracker></SiteChangeTracker>,
    children: [
      {
        path: 'finish',
        element: <Finish />,
      },
      {
        path: '',
        element: <EnquiryPage />,
      },
      {
        path: 'consultation',
        element: <ConsultationPage />,
      },
      {
        path: 'reservation',
        children: [
          {
            path: 'finish',
            element: <ThankYouReservation></ThankYouReservation>,
          },
          {
            path: 'payment-cancelled',
            element: <PaymentErrorReservation></PaymentErrorReservation>,
          },
          {
            path: '',
            element: <Reservation />,
          },
        ],
      },
      {
        path: 'offer-package',
        children: [
          {
            path: 'finish',
            element: <ThankYouOfferPackage></ThankYouOfferPackage>,
          },
          {
            path: 'payment-cancelled',
            element: <PaymentErrorOfferPackage></PaymentErrorOfferPackage>,
          },
          {
            path: '',
            element: <Main />,
          },
        ],
      },
      {
        path: 'offer-reservation',
        children: [
          {
            path: 'finish',
            element: <ThankYouOfferPackage></ThankYouOfferPackage>,
          },
          {
            path: 'payment-cancelled',
            element: <PaymentErrorOfferPackage></PaymentErrorOfferPackage>,
          },
          {
            path: '',
            element: <Booking />,
          },
        ],
      },
      {
        path: 'voucher',
        children: [
          {
            path: 'finish',
            element: <ThankYouVoucher></ThankYouVoucher>,
          },
          {
            path: 'payment-cancelled',
            element: <PaymentErrorVoucher></PaymentErrorVoucher>,
          },
          {
            path: '',
            element: <Voucher />,
          },
        ],
      },
    ],
    errorElement: <ErrorFallback></ErrorFallback>,
  },
]);

const logError = (error: Error, info: ErrorInfo) => {
  console.error('ErrorBoundary caught an error: ', error, info);
};

root.render(
  <StrictMode>
    <ErrorBoundary
      fallback={<ErrorFallback></ErrorFallback>}
      onError={(err, inf) => logError(err, inf)}
    >
      <Suspense fallback={'Loading'}>
        <I18nextProvider i18n={i18n} defaultNS={'common'}>
          <RouterProvider router={router}></RouterProvider>
        </I18nextProvider>
      </Suspense>
    </ErrorBoundary>
  </StrictMode>
);
