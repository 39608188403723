import { useTranslation } from 'react-i18next';
import Header from './header';
import { IOfferPackageApi, OfferPackageApi } from '../services/offerPackageApi';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IOfferPackageDto } from '../model/IOfferPackageDto';
import Offer from './offer';
import Introduction from './introduction';
import Footer from './footer';
import { IOfferDto } from '../model/IOfferDto';
import Form from './form';
import {
  CureApi,
  ICureApi,
  INotificationService,
  IProductApi,
  NotificationService,
  ProductApi,
} from '../services';
import Overlay from '../ui/overlay';
import { DetoxPackage, General, Spa, Yoga } from './language-parts';
import { IRoomCategoryApi, RoomCategoryApi } from '../services/roomCategoryApi';
import { ITreatmentApi, TreatmentApi } from '../services/treatmentApi';
import {
  getGaUserIdFromEmail,
  getShaStringFromValue,
  gtmEvent,
  avg,
  getHashedPhoneNumber,
} from '../utility';
import { IGaItemDto, IGaPurchaseEventDto } from '../model/analytics';
import { MapAllToAnalyticsItem } from '../mapping';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export function Main() {
  const [searchParams] = useSearchParams();
  const offerPackageId = searchParams.get('offer-package');
  if (!offerPackageId) {
    throw new Error();
  }
  const notificationService: INotificationService = new NotificationService();
  const service: IOfferPackageApi = new OfferPackageApi();
  const cureApiService: ICureApi = new CureApi();
  const productApiService: IProductApi = new ProductApi();
  const roomCategoryApiService: IRoomCategoryApi = new RoomCategoryApi();
  const treatmentApiService: ITreatmentApi = new TreatmentApi();

  const { t } = useTranslation();

  const [offerPackage, setOfferPackage] = useState<IOfferPackageDto>();
  const [selectedOffer, setSelectedOffer] = useState<IOfferDto>();

  const onTrack = async (event: string, offers: IOfferDto[]): Promise<void> => {
    if (!offerPackage) {
      return;
    }

    await delay(1000);

    try {
      console.log('tracking ' + event);

      //TRACK
      const items: IGaItemDto[] = [];

      const cureList = offers.flatMap((e) => e.offerCureList);
      for (let index = 0; index < cureList.length; index++) {
        const element = cureList[index];
        const cure = await cureApiService.getById(element.cureId);

        items.push(
          MapAllToAnalyticsItem(
            {
              ...cure,
              id: `cure_${cure.id}`,
            },
            (element.offerPrice ?? element.price) / element.amount,
            element.amount
          )
        );
      }

      const productList = offers.flatMap((e) => e.offerProductList);
      for (let index = 0; index < productList.length; index++) {
        const element = productList[index];
        const product = await productApiService.getById(element.productId);

        items.push(
          MapAllToAnalyticsItem(
            {
              ...product,
              id: `product_${product.id}`,
            },
            (element.offerPrice ?? element.price) / element.amount,
            element.amount
          )
        );
      }

      const offerRoomCategoryList = offers.flatMap(
        (e) => e.offerRoomCategoryList
      );
      for (let index = 0; index < offerRoomCategoryList.length; index++) {
        const element = offerRoomCategoryList[index];
        const room = await roomCategoryApiService.getById(
          element.roomCategoryId
        );

        items.push(
          MapAllToAnalyticsItem(
            {
              ...room,
              id: `room_${room.id}`,
            },
            element.offerPrice ?? element.price,
            1
          )
        );
      }

      const offerTreatmentList = offers.flatMap((e) => e.offerTreatmentList);
      for (let index = 0; index < offerTreatmentList.length; index++) {
        const element = offerTreatmentList[index];
        const treatment = await treatmentApiService.getById(
          element.treatmentId
        );

        items.push(
          MapAllToAnalyticsItem(
            {
              ...treatment,
              id: `treatment_${treatment.id}`,
            },
            element.offerPrice ?? element.price,
            1
          )
        );
      }

      const payload: IGaPurchaseEventDto = {
        currency: 'EUR',
        value: Math.round(avg(offers, (e) => e.price) * 100.0) / 100.0,
        transaction_id: `offer_${await getShaStringFromValue(
          offers.map((e) => e.id).join('_')
        )}`.substring(0, 60),
        items: items,
      };

      gtmEvent(event, await getGaUserIdFromEmail(offerPackage.contactMail), {
        ...payload,
        phone: getHashedPhoneNumber(offerPackage.contactPhone),
      });
    } catch {
      console.log('could not track ' + event);
    }
  };

  useEffect(() => {
    service
      .getById(offerPackageId)
      .then((e) => setOfferPackage(e))
      .finally();
  }, [offerPackageId]);

  useEffect(() => {
    if (offerPackage) {
      onTrack('offer_view', offerPackage.offerList);
    }
  }, [offerPackage]);

  useEffect(() => {
    if (selectedOffer) {
      onTrack('offer_select', [selectedOffer]);
    }
  }, [selectedOffer]);

  if (!offerPackage) {
    return <p>Loading...</p>;
  }

  const now = new Date();

  return (
    <div className="mb-24">
      <Header></Header>

      <Introduction
        firstname={offerPackage.contactFirstname}
        lastname={offerPackage.contactLastname}
        isOffer={true}
      ></Introduction>

      <div className="container space-y-4 mt-4">
        {selectedOffer ? (
          <Form
            offerPackage={offerPackage}
            offer={selectedOffer}
            notificationService={notificationService}
          ></Form>
        ) : (
          <div className="container max-w-3xl px-2 sm:px-0">
            <div className="space-y-4">
              {offerPackage.offerList.map((offer, idx) => (
                <Offer
                  validUntil={offerPackage.validUntil}
                  onSelect={
                    offerPackage.deletedAt === null &&
                    offerPackage.validUntil > now
                      ? () => {
                          window.scrollTo(0, 0);
                          setSelectedOffer(offer);
                        }
                      : undefined
                  }
                  index={idx}
                  offer={offer}
                  key={offer.id}
                ></Offer>
              ))}

              <DetoxPackage></DetoxPackage>

              <Spa></Spa>

              <Yoga></Yoga>

              <General></General>
            </div>
          </div>
        )}

        <Footer></Footer>
      </div>

      <Overlay service={notificationService}></Overlay>
    </div>
  );
}

export default Main;
